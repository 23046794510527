<!-- Account.vue -->
<template>
    <v-container>
        <v-icon large color="blue-grey darken-2">mdi-account-details</v-icon>
        <v-subheader>Account Information</v-subheader>

        <v-card class="mb-2">
            <v-card-title>Display Name: {{ user.displayName }}</v-card-title>
            <v-card-subtitle>Email: {{ user.email }}</v-card-subtitle>
        </v-card>

        <!-- Display the User's Current Subscription Level and Usage -->
        <v-card class="mb-5">
            <v-card-title>Your Current Plan: {{ this.plan }}</v-card-title>
            <v-card-text>
                <p>Usage Count: {{ usageCount }} / {{ maxUsage }} </p>
            </v-card-text>
        </v-card>

        <v-btn @click="updateSubscription()" color="primary">
            <v-progress-circular v-if="isUpdatingSubscription" indeterminate size="18"></v-progress-circular>
            <span v-else>Modify Subscription</span>
        </v-btn>
        <br />
        <br />
        <v-btn @click="authorizeYouTubeAPI" color="secondary">
            <v-progress-circular v-if="isAuthorizingYouTubeAPI" indeterminate size="18"></v-progress-circular>
            <span v-else>
                Authorize
                <img src="../assets/yt_logo_rgb_dark.png" alt="YouTube Icon" style="height: 18px; margin-left: 5px;">
            </span>
        </v-btn>
        <br />
        <br />
        <Destroy />
    </v-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { logEvent, getAnalytics } from 'firebase/analytics';
import axios from 'axios';
import Destroy from "@/components/Destroy";

export default {
    data() {
        return {
            isUpdatingSubscription: false,
            isAuthorizingYouTubeAPI: false
        };
    },
    computed: {
        ...mapGetters([
            'isUserAuthenticated',
            'user',
            'usageCount',
            'plan',
            'isOriginalPlanActive',
            'maxUsage',
            'hasExceededUsageLimit',
            'isMaxed'
        ]),
    },
    components: {
        Destroy
    },
    mounted() {
        // Check if the URL contains the YouTube API token, indicating that the user
        // has just been redirected back from the YouTube auth page
        if (window.location.href.includes('token=')) {
            this.afterYouTubeAuth();
        }
        const queryParams = new URLSearchParams(window.location.search);
        if (queryParams.has('fromStripe')) {
            console.log('Updating Subscription')
            this.checkSubscriptionAndLogEvent();
        }
    },
    methods: {
        ...mapActions(['fetchUserData']),
        async updateSubscription() {
            this.isUpdatingSubscription = true;
            if (!this.user.uid) {
                // User ID is null, do not proceed
                console.error("User is not authenticated");
                return;
            }

            try {
                const token = this.user.accessToken;
                const headers = {
                    'Authorization': `Bearer ${token}`
                };
                const response = await axios.post(`${process.env.VUE_APP_API_ADDRESS}/create_portal_session`, {
                    subscription_level: this.plan,
                    user_id: this.user.uid,
                    email: this.user.email
                }, {
                    headers: headers
                });

                // Redirect to the Stripe Customer Portal
                const portalUrl = response.data.url;
                window.location.href = portalUrl;

            } catch (error) {
                console.error("Error starting subscription: ", error);
            } finally {
                this.isUpdatingSubscription = false;
            }
        },
        async checkSubscriptionAndLogEvent() {
            await this.fetchUserData(this.user.uid);
            const currentPlan = this.plan;
            console.log("Current Plan is: ", currentPlan);

            if (currentPlan === 'Pro Monthly') {
                logEvent(getAnalytics(), 'pro_monthly_subscription');
            } else if (currentPlan === 'Premium Monthly') {
                logEvent(getAnalytics(), 'premium_monthly_subscription');
            }
        },
        async authorizeYouTubeAPI() {
            this.isAuthorizingYouTubeAPI = true;
            try {
                const token = this.user.accessToken;
                const headers = {
                    'Authorization': `Bearer ${token}`
                };
                const response = await axios.get(`${process.env.VUE_APP_API_ADDRESS}/youtube/login`, {
                    headers: headers
                });
                const auth_url = response.data.auth_url;
                // Redirect the user to the consent screen
                window.location.href = auth_url;
            } catch (error) {
                console.error("Failed to get YouTube auth URL", error);
            } finally {
                this.authorizeYouTubeAPI = false;
            }
        },
        async afterYouTubeAuth() {
            console.log("afterYouTubeAuth")
            // Called after the user is redirected back from the YouTube auth page
            const token = /* Retrieve the token from the URL or wherever you've stored it */
                // Store the token in local storage or wherever you're storing auth tokens
                localStorage.setItem('youtubeToken', token);
        }
    },
};
</script>