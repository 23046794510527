<template>
    <div>
        <!-- The Button to open the dialog -->
        <v-btn @click="accountDeletionDialog = true" color="error">Delete My Account</v-btn>

        <v-dialog v-model="accountDeletionDialog" max-width="400px">
            <v-card>
                <v-card-title>Confirm Account Deletion</v-card-title>
                <v-card-text>
                    Are you sure you want to permanently delete your account? This action cannot be undone. You will lose
                    access to all of your data and your subscriptions will be canceled.
                </v-card-text>
                <v-card-actions>
                    <v-btn @click="accountDeletionDialog = false">Cancel</v-btn>
                    <v-btn :loading="isDeleting" @click="deleteAccount" color="error">Yes, Delete</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="passwordDialog" max-width="400px">
            <v-card>
                <v-card-title>Re-enter Password</v-card-title>
                <v-card-text>
                    <v-text-field label="Enter your password" v-model="password" type="password"></v-text-field>
                </v-card-text>
                <v-card-actions>
                    <v-btn @click="passwordDialog = false">Cancel</v-btn>
                    <v-btn :loading="isConfirming" @click="confirmPassword" color="error">Confirm</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>


        <!-- The Snackbar -->
        <v-snackbar v-model="snackbar" :timeout="3000" :color="snackbarColor">
            {{ snackbarMessage }}
            <v-btn text @click="snackbar = false">
                Close
            </v-btn>
        </v-snackbar>

        <v-snackbar v-if="errorMessage" :timeout="5000" color="error">
            {{ errorMessage }}
            <v-btn text @click="errorMessage = ''">
                Close
            </v-btn>
        </v-snackbar>
    </div>
</template>
  
<script>
import { getAuth, EmailAuthProvider, reauthenticateWithCredential, deleteUser, GoogleAuthProvider, reauthenticateWithPopup } from 'firebase/auth';
import { getFirestore, doc, getDoc, deleteDoc } from 'firebase/firestore';
import axios from "axios";
import { mapGetters } from 'vuex';

export default {
    data() {
        return {
            accountDeletionDialog: false,
            passwordDialog: false,
            password: '',
            errorMessage: '',
            snackbar: false,
            snackbarMessage: '',
            snackbarColor: '',
            isDeleting: false,
            isConfirming: false
        };
    },
    computed: {
        ...mapGetters([
            'user'
        ])
    },
    methods: {
        confirmPassword() {
            this.isConfirming = true;
            this.passwordDialog = false;
            this.$emit('passwordDialogConfirmed');
            this.isConfirming = false;
        },
        async deleteAccount() {
            this.isDeleting = true;
            this.errorMessage = ''; // Reset error message
            const auth = getAuth();
            const user = auth.currentUser;

            try {
                if (!user) {
                    throw new Error("No user is currently signed in");
                }

                // Re-authentication
                if (user.providerData[0].providerId === "password") {
                    // Open password dialog and halt function execution
                    this.passwordDialog = true;
                    await new Promise((resolve, reject) => {
                        this.$once('passwordDialogConfirmed', resolve);
                    });

                    const email = user.email;
                    const credential = EmailAuthProvider.credential(email, this.password);
                    await reauthenticateWithCredential(user, credential);

                } else if (user.providerData[0].providerId === "google.com") {
                    const provider = new GoogleAuthProvider();
                    await reauthenticateWithPopup(user, provider);
                } else {
                    throw new Error("Unsupported sign-in method");
                }
                // Initialize userDoc here
                const db = getFirestore();
                const userDoc = doc(db, "users", user.uid);
                // 1. Cancel any active subscriptions
                const docSnap = await getDoc(userDoc);
                const userData = docSnap.data();

                const token = this.user.accessToken;
                const headers = { 'Authorization': `Bearer ${token}` };

                const stripeCustomer = {
                    user_id: this.$store.getters.user.uid,
                    email: this.$store.getters.user.email,
                    subscription_level: userData.subscription_level,
                    subscription_id: userData.subscription_id
                };
                await axios.delete(`${process.env.VUE_APP_API_ADDRESS}/cancel_subscription/`, {
                    headers: headers,
                    data: stripeCustomer
                });

                // 2. Delete user data from Firestore
                await deleteDoc(userDoc);

                // 3. Delete the user's account
                await deleteUser(user);

                // Close the dialog and show the snackbar
                this.accountDeletionDialog = false;
                this.passwordDialog = false;
                this.snackbarMessage = 'Account deleted successfully.';
                this.snackbarColor = 'success';
                this.snackbar = true;

                // Redirect to another page after snackbar timeout or immediately
                setTimeout(() => this.$router.push("/"), 3000);

            } catch (error) {
                // Handle specific error message for incorrect password
                console.error("Error deleting account: ", error);

                // Check for the specific error of incorrect password
                if (error.code === 'auth/wrong-password') {
                    this.errorMessage = 'Incorrect password. Please try again.';
                } else if (error.code === 'auth/missing-password') {
                    this.errorMessage = 'Password is missing. Please enter your password.';
                } else {
                    this.errorMessage = 'An error occurred. Please try again later.';
                }

                // If you are using snackbar for displaying the error
                this.snackbarMessage = this.errorMessage;
                this.snackbarColor = 'error';
                this.snackbar = true;
            } finally {
                this.isDeleting = false; // Reset loading state
            }
        },
    }
};
</script>